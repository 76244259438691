<template>
    <div class="mediaContainer">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('audio.download-from-audio-library') }}</h1>
        </b-col>
        <b-col cols="12">
          <SearchAndFilterPublic @searchVideo="searchVideo" type="audio" />
        </b-col>
        <b-col cols="12">
          <b-overlay :show="showOverlay">
            <MediaAudioGrid :media="media" />
            <b-row v-if="loadMore === true">
              <b-col>
                <p class="text-center">
                  <b-button variant="primary" @click="loadMoreData" class="my-4" size="lg">
                    {{ $t('general.load-more') }} ({{ (page + 1) + "/" + totalPages }})
                  </b-button>
                </p>
              </b-col>
            </b-row>
          </b-overlay>
          <div v-if="!noResult">
            No result
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SearchAndFilterPublic from '@/components/util/SearchAndFilterPublic.vue';
import MediaAudioGrid from '@/components/util/MediaAudioGrid.vue';
export default {
  name : "AllAudio",
  components: {  SearchAndFilterPublic, MediaAudioGrid  },
  data() {
    return {
      media: [],
      tags: [],
      showOverlay: false,
      loadMore: false,
      totalPages: 0,
      page: 0,
      noResult: true
    }
  },
  async created() {
    if (window.sessionStorage.getItem("audioPagination")) {
      this.media = this.decodeJSON(window.sessionStorage.getItem("audioPagination"));
      this.loadMore = window.sessionStorage.getItem("audioPaginationHasNext");
      this.page = window.sessionStorage.getItem("audioPaginationPage");
    } else {
      this.setAudioData()
    }
  },

  methods: {
    loadMoreData() {
      this.page++;
      this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/audio/?page=' + this.page).then(r => {
      this.media = this.media.concat(r.data.content);
      this.loadMore = r.data.hasNext;
      window.sessionStorage.setItem("audioPagination", this.encodeJSON(this.media));
      window.sessionStorage.setItem("audioPaginationHasNext", r.data.hasNext);
      window.sessionStorage.setItem("audioPaginationPage", this.page);
      window.sessionStorage.setItem("audioPaginationTotalPages", r.data.totalPages);
      }).catch(e => {
          this.handleError(e);
      })
    },

    async searchVideo(filter, sort) {
      this.media = [];
      this.showOverlay = true;
      await this.$http.post("/api/media/search?sort=" + sort, filter).then((r) => {
        this.noResult = r.data.hasContent;
        this.media = r.data.content;
        this.loadMore = r.data.hasNext;
      }).catch((e) => { console.log(e) });
      this.showOverlay = false;
    },

    async setAudioData() {
      this.showOverlay = true;
      await this.$http.get('/api/media/all/' + this.$root.lang.IANA + '/audio/?page=' + this.page).then(r => {
          this.media = r.data.content;
          this.loadMore = r.data.hasNext;
          this.totalElements = r.data.totalElements;
          this.totalPages = r.data.totalPages;
          //console.log(r.data);
      }).catch(e => {
        this.handleError(e);  
      });
      this.showOverlay = false;
    }

  },
  computed: {
    _lang: function() {
      return this.$root.lang
    }
  },
  watch:{
    _lang : async function() {
      this.setAudioData();
    }
  }
}
</script>

<style></style>
